import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./scss/style.scss";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const TwoFactorAuth = React.lazy(() =>
  import("./views/pages/login/TwoFactorAuth")
);
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

class App extends Component {
  render() {
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => {
                  let user = localStorage.getItem("currentUser");
                  let authUser = localStorage.getItem("authUser");
                  if (user)
                    return (
                      <Redirect
                        to={{
                          pathname: "/",
                        }}
                      />
                    );
                  if (authUser) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/2fa",
                        }}
                      />
                    );
                  }
                  return <Login {...props} />;
                }}
              />
              <Route
                exact
                path="/2fa"
                name="Verify your account"
                render={(props) => {
                  let user = localStorage.getItem("currentUser");
                  let authUser = localStorage.getItem("authUser");
                  if (user || !authUser)
                    return (
                      <Redirect
                        to={{
                          pathname: "/",
                        }}
                      />
                    );
                  return <TwoFactorAuth {...props} />;
                }}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => {
                  let user = localStorage.getItem("currentUser");
                  let authUser = localStorage.getItem("authUser");
                  if (!user) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/login",
                          state: { from: props.location },
                        }}
                      />
                    );
                  }
                  if (authUser) {
                    return (
                      <Redirect
                        to={{
                          pathname: "/2fa",
                          state: { from: props.location },
                        }}
                      />
                    );
                  }
                  return <TheLayout {...props} />;
                }}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
        <ToastContainer />
      </>
    );
  }
}

export default App;
